.contentContainer {
    padding: 40px 80px 60px
}

.ant-table-wrapper.clickable{
    tr{
        cursor: pointer;
    }

    tr:hover td{
        background: #d6dbe3;
    }

    tr.ant-table-placeholder:hover td{
        background: #ffffff;
    }
}

.ant-table-wrapper.finance-table {
    .ant-table-footer, tbody tr td{
        padding: 4px 8px;
        font-size: 1.4em;
        font-weight: bold;
        background: #ffffff;
    }

    .ant-table-footer{
        border-top: 1px solid #cccccc;
    }
}

.app-input{
    width: 100%;
}

.app-hr{
    color: #f0f0f0;
    margin-bottom: 1em;
}
.ant-radio-group {
    display: flex;
}
.ant-radio-wrapper{
    display: flex;
    align-items: center;
}

.ant-layout-content.site-layout-content {
    padding: 0px;
}

@media (max-width: 992px) {
    .contentContainer {
        padding: 20px 20px 40px
    }
}