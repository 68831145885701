// @import "~antd/lib/style/index";

.container {
    width: 100%;
    height: 100%;
    // display: flex;
    // align-self: center;
    margin: auto;
}

@mixin make-container($minWidth, $breakpoint){
    @media (min-width: $minWidth) {
        .container {
            max-width: $breakpoint;
        }
    }
}

$screen-xs: 480px;
$screen-xs-min: $screen-xs;

// Small screen / tablet
$screen-sm: 576px;
$screen-sm-min: $screen-sm;

// Medium screen / desktop
$screen-md: 768px;
$screen-md-min: $screen-md;

// Large screen / wide desktop
$screen-lg: 992px;
$screen-lg-min: $screen-lg;

// Extra large screen / full hd
$screen-xl: 1200px;
$screen-xl-min: $screen-xl;

// Extra extra large screen / large desktop
$screen-xxl: 1600px;
$screen-xxl-min: $screen-xxl;


@include make-container($screen-xs-min, $screen-xs);
@include make-container($screen-sm-min, $screen-sm);
@include make-container($screen-md-min, $screen-md);
@include make-container($screen-lg-min, $screen-lg);
@include make-container($screen-xl-min, $screen-xl);
@include make-container($screen-xxl-min, $screen-xxl); // Optional


// .container{
//     .ant-row{
//         width: 100%;
//         // margin: 0px;
//     }
// }