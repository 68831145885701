@import url('https://fonts.googleapis.com/css?family=Mukta'); 

body {
    margin: 0;
    padding: 0;
    font-family: 'Mukta', sans-serif;
}

ol, ul{
    padding-left: 1em;
}


/* Layout */
.contentContainer {
    padding: 40px 80px 60px
}

/* End Layout */

/* Alert */

.swal-text {
    text-align: center;
}

.app-modal .ant-modal-content{
    border-radius: 8px;
    overflow: hidden;
}

.app-modal .ant-modal-header{
    padding: 18px 24px 16px;
}

.app-modal .ant-modal-title{
    font-weight: bold;
    color: #072A6F;
    font-size: 1.2em;
}

.user-header {
    font-size: 24;
}

/* End Alert */


/* Typography */

.ant-typography.app-title{
    margin-bottom: .5em;
    color: #072A6F;
}

/* End Typography */


/* Form */

.ant-input.app-form{
    border-radius: 4px;
}

/* End Form */


/* Buttons */

.app-btn {
    width: auto;
    height: auto;
    padding: .5em 1em;
    transition: .3s;
    border: 1px solid  #d9d9d9;
    border-radius: 4px;
    /* font-weight: bold; */
}

.app-btn.block{
    width: 100%;
}

.app-btn.padded{
    padding: .5em 2em;
}

.app-btn.wide{
    padding-left: 4em;
    padding-right: 4em;
}

.app-btn.circle{
    padding: .5em 2em;
    border-radius: 40px;
}

.app-btn.lg{
    padding: .75em 2em;
    font-size: 1.1em;
}

.app-btn.primary:not([disabled]){
    background-color: #FFE600;
    border: 1px solid  #FFE600;
    color: #072A6F;
}

.app-btn.primary:not([disabled]):not(.no-hover):hover{
    color: #FFE600;
    background-color: #072A6F;
    border-color: #072A6F;
}

.app-btn.primary:not([disabled]):not(.no-hover):focus{
    /* color: #FFE600; */
    /* background-color: #072A6F; */
    border-color: #072A6F;
}


.app-btn.secondary:not([disabled]){
    background-color: #072A6F;
    border: 1px solid  #072A6F;
    color: #FFE600;
}

.app-btn.secondary:not([disabled]):not(.no-hover):hover{
    color: #072A6F;
    background-color: #FFE600;
    border-color: #FFE600;
}

.app-btn.secondary:not([disabled]):not(.no-hover):focus{
    /* color: #FFE600; */
    /* background-color: #072A6F; */
    border-color: #072A6F;
}

.app-btn.orange:not([disabled]){
    background-color: #ef712b;
    border: 1px solid  #ef712b;
    color: #ffffff;
}


.app-btn.orange:not([disabled]):hover, .app-btn.orange:not([disabled]):focus{
    background-color: transparent;
    color: #ef712b;
}


.app-btn.danger:not([disabled]){
    background-color: #EB5757;
    border: 1px solid  #EB5757;
    color: #ffffff;
}


.app-btn.danger:not([disabled]):hover, .app-btn.danger:not([disabled]):focus{
    background-color: transparent;
    color: #EB5757;
}

.app-btn.success:not([disabled]){
    background-color: #00a345;
    border: 1px solid  #00a345;
    color: #ffffff;
}


.app-btn.success:not([disabled]):hover, .app-btn.success:not([disabled]):focus{
    background-color: transparent;
    color: #00a345;
}


/* Buttons */

/* Card */

.app-card{
    border-radius: 8px;
    /* padding: 1em; */
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    /* min-width: 530px; */
}

.app-card.paper{
    background: #f0f0f0;
}

.app-card.paper .ant-card-body{
    padding: 15px 20px;
}

/* App.js */
.app-layout {
    background-color: #F5F6FA;
    min-height: 100vh;
}

.app-footer{
    padding: 1em 2em;
    background: #191d3d;
    /* box-shadow: 0px -5px 10px rgba(0, 0, 0, .1); */
    /* color: #F5F6FA; */
    color: #A2A5BB;
    /* font-weight: ; */
}

/* End App.js */


/* Login */
.card-site-login{
    display: flex;
    flex-direction: column;
    /* position: absolute; */
    width: 100%;
    /* height: 450px; */

    /* Secondary */

    border: 4px solid #032464;
    background-color: #191d3d;
    box-sizing: border-box;
    border-radius: 16px;
    align-items: center;
    justify-content: center;
}

.card-site-login .ant-card-body{
    width: 100%;
}

.card-site-login .ant-card-body input{
    width: 100%;
}

.title-container {
    display: flex;
    flex-direction: column;
    left: 20%;
    top: 15%;
    background-color: #191d3d;
    border: 0px;
}

.title-login {
    font-size: 4.5em;
    line-height: 100%;
    color: #fff;
}

.ant-form-item.no-handler .ant-input-number-handler-wrap {
    display: none;
}

@media screen and (max-width: 1366px) {
    .title-login {
        font-size: 3.5em;
    }
}

@media screen and (max-width: 992px) {
    .title-login {
        font-size: 2.75em;
    }
}

@media screen and (max-width: 768px) {
    .title-login {
        font-size: 2.25em;
    }
}



@media screen and (max-width: 576px) {

    .site-layout-forgotpass{
        margin: 0px;
    }

    .card-site-login{
        display: flex;
        flex-direction: column;
        left: 0;
        /* Secondary */
    
        border: 4px solid #032464;
        background-color: #191d3d;
        box-sizing: border-box;
        border-radius: 16px;
        align-items: center;
    }

}



.label-login{
    color: #ffffff;
    font-size: 24px;
}

.site-layout-login{
    height: 100%;
    display: flex;
    align-items: center;
    background-color: #191d3d;
    justify-content: center;
}

.login-form-button {
    width: 200px;
    height: 46px;
    background-color: #FFE600 ;
    border-color: #FFE600 ;
    color: #191d3d ;
    border-radius: 8px ;
    font-weight: bold;
    font-size: 16px;
}

.form-group-login,
.form-group{
    border-radius: 8px ;
    /* width: 400px ; */
    height: 50px;
    align-self: center;
}

.form-group-modal,
.form-group{
    border-radius: 16px ;
    /* width: 350px ; */
    margin-top: 2em;
}

.label-modal {
    width: 437px;
    height: 70px;
    left: 48px;
    top: 108px;

    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    color: #000000;
}

.label-modal-login{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    color: #000000;
}

.modal-login {
    padding: 24px;
    word-wrap: break-word;
    position: relative ;
    width: 433px ;
    height: 266px ;
}

.text-label-matpel {
    font-family: 'Mukta', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 1.5;
    color: #222222;
}

.text-label-waktu {
    font-family: 'Mukta', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 1.5;
    color: #222222;
}

.modal-login,
.ant-modal-content {
    border-radius: 16px ;
}

.input.ant-input.form-group-login{
margin-top: 1em;
}

.btn-modal-login{
    width: 123px ;
    height: 32px ;
    color: #ffffff ;
    background: #27AE60 ;
    box-shadow: 0px 4px 16px rgba(51, 51, 51, 0.13) ;
    border-radius: 16px ;
}

.steps-content {
    min-height: 200px;
}

.steps-action {
    text-align: right;
    margin-top: 20px;
}

.form-modal .ant-modal-content {
    border-radius: 8px;
}

.form-modal-middle .ant-modal-content {
    width: 600px;
    max-width: 100%;
    margin: auto;
    border-radius: 10px;
}

.form-modal .ant-modal-body, .form-modal-middle .ant-modal-body {
    padding-top: 35px;
    padding-bottom: 35px;
    padding-right: 35px;
    padding-left: 35px;
}

.input-modal {
    border-radius: 10px;
    background: #f2f2f2;
}

.input-modal .ant-input {
    background: #f2f2f2;
}

.form-button {
    width: 150px;
    height: 46px;
    background-color: #FFE600 ;
    border-color: #FFE600 ;
    color: #191d3d ;
    border-radius: 8px ;
    font-weight: bold;
    font-size: 16px;
}

.form-button-white {
    width: 150px;
    height: 46px;
    background-color: #FFF ;
    border-color: #d9d9d9 ;
    color: #191d3d ;
    border-radius: 8px ;
    font-weight: bold;
    font-size: 16px;
}

/* End of login  */

/* Forgot Password */
.site-layout-forgotpass{
    height: 100%;
    background-color: #f5f6fa;
    margin: 20px;
}

.forgotpass-form-button {
    margin-top: 10px;
    width: 200px;
    height: 46px;
    background-color: #FFE600 ;
    border-color: #FFE600 ;
    color: #191d3d ;
    border-radius: 8px ;
    font-weight: bold;
    font-size: 16px;
}

.form-group-forgotpass {
    border-radius: 24px ;
    width: 300px ;
    height: 40px;
    padding-left: 1em;
    align-self: left;
    font-weight: bold;
    font-size: 16px;
    color: #072A6F;
}
/* End of Forgot Password */


/* HeaderLayout.js */
.ant-layout-content{
    padding: 0px 2em;
}

.site-layout-content{
    margin: 2em auto 0px;
    min-width: 630px;
    /* width: 80%; */
}
.site-layout-continer{
    margin: 2em auto 0px;
}


.header-loyola.ant-layout-header{
    padding: 10px 80px;
    background-color: #f5f6fa;
    position: fixed;
    width: 100%;
    /* min-width: 770px; */
    z-index: 999;
    height: 80px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}

.header-loyola .wrapper {
    display: flex;
    justify-content: space-between;
}

.header-loyola .name {
    color: #FFF;
    margin-right: 10px;
}

.header-loyola .title {
    font-weight: bold;
    font-size: 24px;
    color: #072A6F;
    text-align: left;
    margin-left: 10px;
    line-height: 1.5;
}

.header-loyola .menu {
    margin-left: 1em;
    font-weight: bold;
    font-size: 20px;
    color: #072A6F;
    cursor: pointer;
}

.header-loyola .dropdown{
    color: rgb(7, 42, 111);
    font-size: 1.4em;
    cursor: pointer;
    margin-left: 1em;
}

.area-header-title {
    text-align: left;
}

.area-header-burger {
    text-align: center;
}

.area-header-menu {
    text-align: center;
}

.site-page-header .ant-page-header-heading-title{
    color: #072A6F;
    font-size: 2em;
}

/* End HeaderLayuot.js */

/* Bip Tester.js */
.card-biptester {
    width: 100%;
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.06);
    border-radius: 16px;
}

.card-interviewer {
    width: 100%;
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.06);
    border-radius: 16px;
}

.title-biptester {
    font-family: 'Mukta', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 33px;
    text-align: right;
    margin-left: 10px;
    color: #000000;
}

/* End Bip Tester.js */
/* Verifikator.js */

.input-search {
    height: 40px;
    border-radius: 32px;
    background: #FFFFFF;
}

.card-verifikator {
    width: 100%;
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.06);
    border-radius: 16px;
}

.title-verifikator {
    font-family: 'Mukta', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 33px;
    text-align: right;

    color: #000000;
}
.title-scanqr {
    font-family: 'Mukta', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    text-align: right;
    color: #072A6F;
}

.text-heading-verifikator {
    font-family: 'Mukta', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 80px;
}

/* End Verifikator.js */

/* Interviewer.js */

.input-search {
    height: 40px;
    border-radius: 32px;
    background: #FFFFFF;
}

.card-interviewer {
    width: 100%;
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.06);
    border-radius: 16px;
}

.title-interviewer {
    font-family: 'Mukta', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 33px;
    text-align: right;

    color: #000000;
}

/* End Interviewer.js */



/* Eksekutif.js */
.admin-get-button {
    width: 200px;
    height: 46px;
    background-color: #072a6f ;
    border-color: #072a6f ;
    color: #fff ;
    border-radius: 8px ;
    font-weight: bold;
    font-size: 16px;
    margin-left: 10px;
}

.admin-update-button {
    width: 150px;
    height: 36px;
    background-color: #072a6f ;
    border-color: #072a6f ;
    color: #FFE600 ;
    border-radius: 8px ;
    font-weight: bold;
    font-size: 14px;
    margin-left: 10px;
}

.finance-update-button {
    width: 80px;
    height: 30px;
    background-color: #072a6f ;
    border-color: #072a6f ;
    color: #FFE600 ;
    border-radius: 8px ;
    font-weight: bold;
    font-size: 14px;
}

/* End Eksekutif.js */

/* Dashboard */

.card-prestasi {
    border-radius: 15px;
    height: 399px;
    overflow: auto hidden;
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 59%, rgba(0, 0, 0, 0.65) 100%), url('../../assets/prestasi.png') no-repeat;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(59%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 0.65))), url('../../assets/prestasi.png') no-repeat;
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 59%, rgba(0, 0, 0, 0.65) 100%), url('../../assets/prestasi.png') no-repeat;
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 59%, rgba(0, 0, 0, 0.65) 100%), url('../../assets/prestasi.png') no-repeat;
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 59%, rgba(0, 0, 0, 0.65) 100%), url('../../assets/prestasi.png') no-repeat;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 59%, rgba(0, 0, 0, 0.65) 100%), url('../../assets/prestasi.png') no-repeat;
    background-size: cover;
    cursor: pointer;
    position: relative;
}

.card-tes {
    height: 399px;
    border-radius: 15px;
    overflow: auto hidden;
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 59%, rgba(0, 0, 0, 0.65) 100%), url('../../assets/tes.png') no-repeat;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(59%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 0.65))), url('../../assets/tes.png') no-repeat;
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 59%, rgba(0, 0, 0, 0.65) 100%), url('../../assets/tes.png') no-repeat;
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 59%, rgba(0, 0, 0, 0.65) 100%), url('../../assets/tes.png') no-repeat;
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 59%, rgba(0, 0, 0, 0.65) 100%), url('../../assets/tes.png') no-repeat;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 59%, rgba(0, 0, 0, 0.65) 100%), url('../../assets/tes.png') no-repeat;
    background-size: cover;
    cursor: pointer;
    position: relative;
}

.card-content {
    position: absolute;
    bottom: 20px;
}

.card-grid {
    width: 100%;
    margin-right: 20px;
    border-radius: 15px;
    border: 1px solid #f0f0f0;
}

.text-heading {
    margin-bottom: 10px !important;
    color: #072A6F;
}

.text-heading-admin {
    margin-bottom: 10px !important;
    margin-left: 240px;
    text-align: right;
    color: #072A6F;
}

.text-title {
    color: #000;
    font-size: 32px;
    font-weight: bold;
}

.text-subtitle {
    color: #000;
    /* font-size: 24px; */
    font-size: 1.2em;
    /* font-weight: bold; */
    padding-left: 5;
}

.container-signature{
    align-items: center;
    justify-content: center;
}

.title-signature {
    font-family: 'Mukta', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color:#000;
}

.signature-button {
    background-color: #191d3d ;
    color: #FFE600 ;
    font-weight: bold;
    font-size: 16px;
}

.verifikasi-wait{
    font-style: 'normal';
    font-weight: 'bold';
    font-size: 24;
    color: '#072A6F'
  }
.verifikasi-succes{
    font-style: 'normal';
    font-weight: 'bold';
    font-size: 24;
    color: '#219653'
}
.verifikasi-fail{
    font-style: 'normal';
    font-weight: 'bold';
    font-size: 24;
    color: '#EB5757'
}
/* End Dashboard */


/* Jalur Pendaftaran */

.card-step {
    min-height: 450px;
    border-radius: 15px;
    overflow: hidden auto;
}

.avatar-step {
    background: #072A6F;
    float: left;
    margin-top: 6px;
    font-weight: 600;
}

.text-desc {
    color: #072A6F;
    font-size: 1.2em;
}

.subtitle{
    font-size: 1.2em;
    margin-bottom: 1em;
    font-weight: bold;
}

/* table */

.app-table{
    margin-bottom: 2em;
}

.app-table td{
    padding: .5em 1em;
}

.app-table tbody tr:hover td{
    background: #f5f6fa;
}

.app-table tbody:before {
    /* This doesn't work because of border-collapse */
    line-height:1em;
    content:"_";
    color:white;
    display:block;
}

.app-table td .ant-input-number{
    width: auto;
}

.req-star{
    display: inline-block;
    margin: auto 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
}

/* Card verifikasi data */
.form-button-tes {
    width: 200px;
    height: 46px;
    background-color: #191d3d ;
    /* border-color: #FFE600 ; */
    color: #FFE600 ;
    border-radius: 8px ;
    font-weight: bold;
    font-size: 16px;
}
/* Card verifikasi data end*/

.ant-upload-select .ant-upload-select-text, .ant-upload{
    width: 100%;
}

.ant-space.space-between{
    width: 100%;
    justify-content: space-between;
}

.ant-space.end{
    width: 100%;
    justify-content: end;
}

/* BipTester.js */
.save-button, .save-button:hover {
    background-color:#FFE600;
    border-radius: 8px;
    color: #072A6F;
    font-family: 'Mukta', sans-serif;
    font-style: normal;
    font-weight: bold;
}

.cancel-button, .cancel-button:hover {
    background-color:#EB5757;
    border-radius: 8px;
    color: #FFFFFF;
    padding: 6px 24px;
    font-family: 'Mukta', sans-serif;
    font-style: normal;
    font-weight: bold;
}

.text-title-admin {
    font-family: 'Mukta', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 40px;
    color:#000000;
    margin-top: 1px;
}

.card-bip {
    width: 100%;
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.06);
    border-radius: 16px;
}

.back-button-bip {
    position: absolute;
    left: -10%;
    color: #46526b!important;
}

.item-penilaian-bip {
    font-family: 'Mukta', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    color: #072A6F;
}

.bip-divider {
    border: 2px solid #E0E0E0;
    margin: 15px 0;
}

.custom-dropdown-admin {
    background-color: #072A6F;
    font-family: 'Mukta', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #FFE600;
    border-radius: 32px;
    padding-right: 60px;
    padding-left: 24px;
}

/* End BipTester.js*/

/* Eksekutif.js */
.btn-aktif-eksekutif, .btn-eksekutif {
    border-radius: 8px;
    font-weight: bold;
}

.btn-eksekutif,
.btn-eksekutif:focus{
    background: #072A6F;
    color: #FFFFFF;
}

.btn-aktif-eksekutif,
.btn-aktif-eksekutif:focus{
    background: #FFFFFF;
    color: #072A6F;
}

.btn-list-eksekutif, .btn-list-eksekutif:hover,
.btn-list-eksekutif:focus{
    background: #072A6F;
    color: #FFE600;
}

.update-button, .update-button:hover{
    background: #27AE60;
    border-radius: 8px;
    font-weight: bold;
    color: #FFFFFF;
}
/* End Eksekutif.js*/

/* dashboard */
.contentDashboard{
    margin-top: 20px;
    margin-right: 80px;
    margin-left: 110px; 
}

.cardDashboard{
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.06);
    border-radius: 16px;
    margin-bottom: 20px;
}

.chart-fill {
    position: 'absolute';
    align-items: 'center';
    justify-content: 'center';
}

.chart-inner-text {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding-top: 43%;
    text-align: center;
    justify-content: 'center';
}

.chart-inner-value{
    font-weight: bold;
    color: #000;
    font-size: 80px;
}

@media (max-width:767px) {
    .contentDashboard{
        margin-top: 20px;
        margin-right: 10px;
        margin-left: 40px; 
    }

    .cardDashboard{
        background: #FFFFFF;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.06);
        border-radius: 16px;
        margin-bottom: 20px;
        min-width: 80%;
    }

    .colDashboard{
        min-width: 80%;
    }
}  
/*  End dashboard */

/* Dropdown Component custom style */
.custom-select .ant-select-selector, .custom-select .ant-select-arrow, .custom-dropdown {
    color: #FFE600 !important;
}
.custom-select .ant-select-selection-placeholder {
    opacity: 0.8!important;
}
.custom-select .ant-select-selector {
    border-radius: 32px!important;
}
.custom-select .ant-select-selector, .custom-dropdown {
    background-color: #072A6F!important;
}

.passwordColor input {
    background: #F2F2F2;
  }

.link-downloaded {
    font-size: 14px;
    color: #FFE600 !important
}

.upload-btn,
.upload-btn:hover,
.upload-btn:focus {
  background: #FFE600;
  border-radius: 20px;
  height: auto;
  padding: 2px 16px;
  color:  #FFE600;
  border-color:  #FFE600;
}

.upload-btn > span {
  font-size: 14px;
  color: #072A6F !important
}
.upload-btn[disabled] > span{
    font-size: 14px;
    color: rgba(0, 0, 0, 0.25) !important
}

.download-btn,
.download-btn:hover,
.download-btn:focus {
    background: #072A6F;
    border-radius: 20px;
    height: auto;
    padding: 2px 16px;
    color:  #072A6F;
    border-color:  #072A6F;
}

.download-btn > span{
    font-size: 14px;
    color: #fff !important
}

.download-btn[disabled] > span{
    font-size: 14px;
    color: rgba(0, 0, 0, 0.25) !important
}

.container-kalkulator-secenario{
    margin-top:20; 
    margin-right:180; 
    margin-left:180
}


@media (max-width: 768px) {
    .container-kalkulator-secenario{
        margin-right:0; 
        margin-left:0
    }
}  

@media (max-width: 992px) {
    .header-loyola.ant-layout-header{
        padding: 10px 20px;
    }

    .header-loyola .menu {
        font-size: 14px;
    }
    .header-loyola .title {
        font-weight: bold;
        font-size: 16px;
        color: #072A6F;
        text-align: left;
        margin-left: 20px;
    }

    .header-loyola .dropdown{
        font-size: 1.2em;
    }
    
    .contentContainer {
        padding: 20px 20px 40px
    }
}  